<template>
  <div class="SecurityInfo">
    <div id="PDFIframe" class="PDFIframe"></div>
    <div style="background-color:white;padding:20px">
      <div style="font-size:14px;color:#000;">音频视频</div>
      <div
        style="margin:20px 0"
        v-for="(item, index) in FindFormApplyDetailData.FormApplySafeFields"
        :key="index"
      >
        <div
          v-if="
            (item.fafd03 == 4 || item.fafd03 == 5) &&
              item.fafd06 &&
              (item.fafd06.toUpperCase().indexOf('MP4') != -1 ||
                item.fafd06.toUpperCase().indexOf('MOV') != -1)
          "
        >
          <p style="text-align:center">
            {{
              item.fafd06.substr(
                item.fafd06.lastIndexOf("/") + 1,
                item.fafd06.length
              )
            }}
          </p>
          <video
            controls
            :src="$store.state.baseUrl + item.fafd06"
            style="width:100%"
          ></video>
        </div>
        <div
          v-if="
            (item.fafd03 == 4 || item.fafd03 == 5) &&
              item.fafd06 &&
              item.fafd06.toUpperCase().indexOf('MP3') != -1
          "
        >
          <p style="text-align:center">
            {{
              item.fafd06.substr(
                item.fafd06.lastIndexOf("/") + 1,
                item.fafd06.length
              )
            }}
          </p>
          <audio
            controls
            :src="$store.state.baseUrl + item.fafd06"
            style="width:100%"
          ></audio>
        </div>
      </div>

      <div style="font-size:14px;color:#000;">沟通记录</div>
      <div
        class="flexLR"
        style="width:100%;margin:20px 0"
        v-if="FindFormApplyDetailData.FormApply.famp11 != 2"
      >
        <van-field
          v-model="fscdInfo.fscd03"
          rows="1"
          autosize
          label=""
          type="textarea"
          placeholder="请输入内容"
        />
        <van-button
          type="info"
          style="width:20%"
          size="mini"
          @click="AddFromSafeComment"
          >评论</van-button
        >
      </div>
      <div
        class="flexLR"
        style="margin:10px 0;"
        v-for="(item, index) in chatList"
        :key="index"
      >
        <div class="flexcolumn">
          <van-image
            round
            width="45"
            height="45"
            :src="$store.state.baseUrl + item.imgurl"
          />
          {{ item.Username }}
        </div>
        <div class="flexcolumn" style="width:80%;align-items: initial;">
          <div style="font-weight:bold;font-size:14px">{{ item.fscd03 }}</div>
          <div class="flexLR" style="width:100%;height:40px">
            <div style="width:80%;color:#919191">{{ item.fscd05 }}</div>
            <div class="flexLR" style="width:20%">
              <!-- <van-button type="info" size="mini" @click="comment = true">评论</van-button>&nbsp;&nbsp; -->
              <van-button
                size="mini"
                style="width:100%"
                type="danger"
                @click="DelFromSafeComment(item.fscd01)"
                v-if="item.fscd04 == $store.state.uid"
                >删除
              </van-button>
            </div>
          </div>
        </div>
      </div>

      <van-dialog
        v-model="comment"
        title="评论"
        @confirm="AddFromSafeComment"
        show-cancel-button
      >
        <van-field
          v-model="fscdInfo.fscd03"
          rows="1"
          autosize
          label="评论内容"
          type="textarea"
          placeholder="请输入内容"
        />
      </van-dialog>

      <van-cell
        title="流程名称"
        :value="FindFormApplyDetailData.FormApply.famp07"
        style="padding:20px 0;"
      />

      <div style="font-size:14px;color:#000;">审核流程</div>

      <div class="flexWrap" v-if="FindFormApplyDetailData.FormAuditFlow.length">
        <div
          v-for="(item, index) in FindFormApplyDetailData.FormAuditFlow.filter(
            (ele) => ele.fmad07 == 1
          )"
          :key="index"
          class="shlcDIV"
        >
          <div
            class="flexcolumn"
            style="height: 100%;position: relative;padding:5px"
          >
            <div
              style="text-align:left;width:100%;height:15%;color: #005aff;font-weight: bold;"
            >
              {{ item.fmad05 == 1 ? "会签" : "或签" }}
            </div>
            <div>{{ item.fmad03 }}</div>
            <div style="position:relative;z-index:1">{{ item.name }}</div>
            <div
              style="position:absolute;bottom:0;right:10px;color:#0095ff;z-index: 0;font-weight:bold;opacity: 0.3;font-size: 36px;"
            >
              {{ index + 1 }}
            </div>
          </div>
        </div>
        <div
          v-for="(item, index) in FindFormApplyDetailData.FormAuditFlow.filter(
            (ele) => ele.fmad07 == 2
          )"
          :key="
            index +
              FindFormApplyDetailData.FormAuditFlow.filter(
                (ele) => ele.fmad07 == 1
              ).length
          "
          class="shlcDIV"
        >
          <div
            class="flexcolumn"
            style="height: 100%;position: relative;padding:5px"
          >
            <div
              style="text-align:left;width:100%;height:15%;color: #005aff;font-weight: bold;"
            >
              抄送人员
            </div>
            <div>{{ item.fmad03 }}</div>
            <div style="position:relative;z-index:1">{{ item.name }}</div>
            <div
              style="position:absolute;bottom:0;right:10px;color:#0095ff;z-index: 0;font-weight:bold;opacity: 0.3;font-size: 36px;"
            >
              {{
                index +
                  FindFormApplyDetailData.FormAuditFlow.filter(
                    (ele) => ele.fmad07 == 1
                  ).length +
                  1
              }}
            </div>
          </div>
        </div>
      </div>

      <div style="font-size:14px;color:#000;">动态记录</div>
      <van-steps
        active-color="#4F6DFA"
        direction="vertical"
        :active="FindFormApplyDetailData.FormAuditRecord.length"
      >
        <van-step
          v-for="(item, index) in FindFormApplyDetailData.FormAuditRecord"
          :key="index"
        >
          <template slot="finish-icon" v-if="item.farc04 == 1">
            <van-icon name="checked" color="#4F6DFA" />
          </template>
          <template slot="finish-icon" v-else>
            <van-icon name="clear" color="red" />
          </template>
          <!-- <h3>{{ item.farc05 }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.farc03 }}</h3> -->
          <h3>
            {{ item.farc03
            }}<van-button
              v-if="item.farc03.includes('驳回')"
              type="info"
              style="width:18%;float: right;border-radius: 25px;"
              size="mini"
              @click="draweropen(item.farc08)"
              >查看</van-button
            >
          </h3>
          <p>{{ item.farc05 }}</p>
        </van-step>
      </van-steps>
      <van-overlay :show="drawer">
        <div class="wrapper" @click.stop>
          <div id="PDFIframe2" class="PDFIframe"></div>
          <van-button type="default" class="pdfbtn" @click="drawer = false"
            >关闭</van-button
          >
        </div>
      </van-overlay>
    </div>
    <!-- <div v-if="list.length > 0">
    </div>
    <div v-else style="color: #0a9efc; margin-top: 100px; text-align: center">
      <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
      <div>暂无数据</div>
    </div> -->
  </div>
</template>

<script>
import {
  FindFormApplyDetail,
  GetFromSafeComment,
  DelFromSafeComment,
  AddFromSafeComment,
} from "@/api";
import Pdfh5 from "pdfh5";
export default {
  name: "QuantityInfo",
  components: {},
  data() {
    return {
      pdfh5: null,
      list: [],
      chatList: [],
      value: "",
      FindFormApplyDetailData: {
        FormApply: {
          famp06: "",
          famp07: "",
        },
        FormAuditRecord: [],
        FormApplySafeFields: [],
        FormAuditFlow: [],
      },
      fscdInfo: {
        fscd02: this.$route.query.id,
        fscd03: "",
        fscd04: this.$store.state.uid,
        fscd05: new Date(),
      },
      comment: false,
      drawer: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    draweropen(val) {
      this.drawer = true;
      this.pdfh5 = new Pdfh5("#PDFIframe2", {
        pdfurl: this.$store.state.baseUrl + val,
      });
    },
    DelFromSafeComment(id) {
      DelFromSafeComment({ id }).then((res) => {
        if (res.Code) {
          this.$Toast("删除成功");
          this.GetFromSafeComment();
        }
      });
    },
    AddFromSafeComment() {
      if (this.fscdInfo.fscd03 == "") {
        this.$Toast.fail("评论不能为空");
        return false;
      }
      AddFromSafeComment({ ...this.fscdInfo }).then((res) => {
        if (res.Code) {
          this.$Toast("评论成功");
          this.GetFromSafeComment();
          this.fscdInfo.fscd03 = "";
        }
      });
    },
    updateData() {
      this.getData();
      this.$emit("updateLoading", "消息");
    },
    async consentBtn(v) {
      let res = await HandleMsg({
        id: v.Id,
        type: v.Type,
        state: 1,
      });
      this.$CodeMessage(res);
      this.getData();
    },
    async refuseBtn(v) {
      let res = await HandleMsg({
        id: v.Id,
        type: v.Type,
        state: 2,
      });
      this.$CodeMessage(res);
      this.getData();
    },
    async deleteBtn(v) {
      let res = await DelMsg({
        id: v.Id,
        type: v.Type,
      });
      this.$CodeMessage(res, "操作成功");
      this.getData();
    },
    async getData() {
      let res = await GetMessage();
      if (res.Code > 0) this.list = res.Data;
    },
    FindFormApplyDetail() {
      FindFormApplyDetail({
        params: {
          id: this.$route.query.id,
          type: 3,
        },
      }).then((res) => {
        this.FindFormApplyDetailData = res.Data;
        this.pdfh5 = new Pdfh5("#PDFIframe", {
          pdfurl:
            this.$store.state.baseUrl +
            this.FindFormApplyDetailData.FormApply.famp06,
        });
      });
    },
    GetFromSafeComment() {
      GetFromSafeComment({
        params: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        this.chatList = res.Data;
      });
    },
  },
  created() {
    this.FindFormApplyDetail();
    this.GetFromSafeComment();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.SecurityInfo {
  margin: 0 auto;

  .shlcDIV {
    border: 1px solid rgb(236, 236, 236);
    width: 200px;
    margin: 12px;
    height: 200px;
    border-radius: 10px;
  }

  #PDFIframe2 {
    /deep/.pageNum {
      color: white;
    }
    /deep/.viewerContainer {
      height: 96vh;
    }
  }
  .wrapper {
    height: 100%;
  }
  .pdfbtn {
    width: 100%;
    height: 6%;
    position: absolute;
    bottom: 0px;
    z-index: 999;
    background-color: #aaaaaa;
  }
}
</style>
